import React, { useState,useEffect } from "react";
import PDFReader from "./PDFReader";
import send_mail from "../utils/email";
import { Header } from "./header";
export const Whitepaper = () => {

  

  return (
    <div>
       
        <PDFReader />
 
    </div>
  );
};
