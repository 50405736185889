/* export const Features = (props) => {
    return (
      <div id='features' className='text-center'>
        <div className='col-md-10 col-md-offset-1 section-title'>
            <h2>{props.data2 ? props.data2.feat : 'Loading'}</h2>
          </div>
        <div className='container '>
          
          <div className='row grid-features'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3 size-features'>
                    {' '}
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : 'Loading...'}
          </div>
        </div>
      </div>
    )
  } */
  



  export const Features = (props) => {
    return (
      <div id='features' className='text-center'>
        <div className='container'>
          <div className='col-md-8 col-md-offset-2 section-title'>
            {/* <h2>FEATURES</h2> */}
            
          </div>
              <img src="img/features/features.jpg" alt="" style={{ width:'100%' }} />
        </div>
      </div>
    )
  }





  