/* import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
export const About = (props) => {
  return (
    <div id='about' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          
        </div>
            <img src="img/about/about.jpg" alt="" style={{ width:'100%' }} />
      </div>
    </div>
  )
} */


import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
export const About = (props) => {
  return (
    <div id='about' className='about'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          
        </div>
            {/* <img src="img/about/about.jpg" alt="" style={{ width:'100%' }} /> */}
      </div>
    </div>
  )
}

