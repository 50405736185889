import Price from "./Pricemarket";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useWindowSize from "../hooks/useWindowSize";
import Axios from "axios";
import { useMemo, useState, useEffect } from "react";
const totolsupply = 1000000000;
export const Header = (props) => {
  const width = useWindowSize();
  const isTablet = width > 1025;
  const [showButton, setshowButton] = useState(false);
  const [lastPrice, setLastPrice] = useState(0);
  const [slowRefresh, setslowRefresh] = useState(true);
  const [open, setOpen] = useState(true);
  const vemoInfo = async () => {
    await Axios.get(
      "  https://api.dex-trade.com/v1/public/ticker?pair=VEMOUSDT"
    ).then((res) => {
      setLastPrice(res.data.data.last);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setslowRefresh(!slowRefresh);
    }, 30000);
  }, [slowRefresh]);

  useMemo(() => vemoInfo(), [slowRefresh]);

  const toggle = (status) => {
    const elSlideBox = document.getElementById("slide-box");
    setOpen(!open);
    if (status) {
      elSlideBox.style.width = "300px";
    } else {
      elSlideBox.style.width = "50px";
    }
    setTimeout(() => {
      setshowButton(!showButton);
    }, 400);
  };

  return (
    <header id="header">
      <div
        className="img-responsive"
        style={{ position: "relative", top: "80px" }}
        /* style={{ position: "relative", top: "130px" }} */
      >
        <div
          id="slide-box"
          onClick={() => {
            toggle(open);
          }}
        >
          {showButton ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor:'transparent'
              }}
            >
              <div className="vemo-coin-info" style={{color:'white',padding:'2rem 1rem',fontSize:'18px',borderRadius:'30px'}}>
                VEMO Price: ${lastPrice} <br />
                Total Supply: {totolsupply.toLocaleString()} <br />
                Market Cap: ${(totolsupply * lastPrice).toLocaleString()}
              </div>
              <div className="" >
                {/* <img
                  src="../img/Button-vemo-info.png"
                  style={{ width: "50px", marginTop: "34px" }}
                  alt=""
                /> */}
              </div>
            </div>
          ) : (
            <div id="animation-example" className="animated bounce">
             {/*  <img
                src="../img/Button-vemo-info.png"
                style={{ width: "50px", marginTop: "34px" }}
                alt=""
              /> */}
            </div>
          )}
        </div>

        <div className="intro">
          {/* <img
            src="../img/Background-12-mascot.png"
            id="animation-children"
            className="animated children_transform"
            alt=""
          /> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-4"></div>
                <div
                  className="col-lg-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "65px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/*  เส้นขอบโลโก้
                    <img
                      className="rotate-border"
                      src="../img/Boder-Logo-.png"
                      alt="logo-"
                      style={{
                        maxWidth: "210px",
                        width: "33vw",
                        position: "absolute",
                        zIndex: "10",
                      }} */}
                    />
                    <img
                      src="../img/logo.png"
                      alt="logo-golfx"
                      style={{ maxWidth: "500px", width: "60vw" }}
                      /* style={{ maxWidth: "210px", width: "33vw" }} */
                    />
                  </div>
                </div>
                <div className="col-lg-4"></div>
              </div>

              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8" style={{ textAlign: "center" }}>
                  <h1 style={{ marginTop: "30px" }}>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                  <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    {props.data ? props.data.learn : "Loading"}
                  </a>{" "}
                  <a
                    href="https://exchange.pancakeswap.finance/#/swap"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    {props.data ? props.data.buy : "Loading"}
                  </a>{" "}
                  <p style={{ paddingTop: "30px" }}>
                    {props.data ? props.data.text : "Loading"}
                  </p>
                </div>
                <div className="col-md-2"></div>
              </div>

            </div>
{/*             {isTablet ? (
              <div className="" style={{display:'flex'}}>
                <div
                  className="social img col-md-4 col-xs-4"
                  style={{ alignSelf: "flex-end" }}
                >
                  <ul>
                    <li>
                      <img src="img/Icon-DexTrade.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon-Alterdice.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon-Azbit.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon-DexGuru.png" alt="" />
                    </li>                    
                  </ul>
                </div>

                <div  
                  className="social img col-md-4 col-xs-4"
                  style={{ alignSelf: "flex-end"}}
                >
                  <img src="img/QR-CODE.png" alt="" style={{maxWidth:"160px",width:'15vw'}}/>
                </div>

                <div
                  className="social img col-md-4 col-xs-4"
                  style={{ alignSelf: "flex-end" }}
                >
                  <ul>
                  <li>
                      <img src="img/Icon-PancakeSwap.png" alt="" />
                    </li>                    
                    <li>
                      <img src="img/Icon1.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon2.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon3.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon4.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon5.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon6.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon7.png" alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="" style={{ alignSelf: "flex-end",display:'flex'}}>
                <Carousel
                  autoPlay
                  emulateTouch
                  infiniteLoop
                  showThumbs=""
                  showStatus=""
                  stopOnHover
                >
                  <div className="social img" style={{display:'flex',height:'140px',justifyContent:'center'}}>
                    <ul style={{alignSelf:'center'}}>
                    <li>
                      <img src="img/Icon-DexTrade.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon-Alterdice.png" alt="" />
                    </li>
                    <li>
                      <img src="img/Icon-Azbit.png" alt="" />
                    </li>
                    </ul>
                  </div>

                  <div className="social img ">  
                    <img src="img/QR-CODE.png" alt="" style={{maxWidth:"180px",width:'30vw'}}/>
                  </div>

                  <div className="social img" style={{display:'flex',height:'140px',justifyContent:'center'}} >
                    <ul style={{alignSelf:'center'}}>
                      <li>
                        <img src="img/Icon1.png" alt="" />
                      </li>
                      <li>
                        <img src="img/Icon2.png" alt="" />
                      </li>
                      <li>
                        <img src="img/Icon3.png" alt="" />
                      </li>
                      <li>
                        <img src="img/Icon4.png" alt="" />
                      </li>
                      <li>
                        <img src="img/Icon5.png" alt="" />
                      </li>
                      <li>
                        <img src="img/Icon6.png" alt="" />
                      </li>
                      <li>
                        <img src="img/Icon7.png" alt="" />
                      </li>
                    </ul>
                  </div>
                </Carousel>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </header>
  );
};
