const nodemailer = require('nodemailer');

// config สำหรับของ gmail
const transporter = nodemailer.createTransport({
    service: 'gmail',
    auth: {
      user: 'thalatchananbaikratokeair@gmail.com', // your email
      pass: 'admin@air4121' // your email password
    }
  });
const send_mail = () =>{
 
      // config สำหรับของ outlook
     
    
      let mailOptions = {
        from: 'thalatchananbaikratokeair@gmail.com',                // sender
        to: 'mafiahos0@gmail.com',                // list of receivers
        subject: 'Hello from sender',              // Mail subject
        html: '<b>Do you receive this mail?</b>'   // HTML body
      };
    console.log("before_transporter")
      transporter.sendMail(mailOptions, function (err, info) {
        if(err)
          console.log(err)
        else
          console.log(info);
     });
}

export default  send_mail;