/* export const Team = (props) => {
      return (
        <div id='team' className='text-center'>
          <div className='container'>
            <div className='col-md-8 col-md-offset-2 section-title'>
              <h2>Our Team</h2>
              
            </div>
                <img src="img/team/our-team.jpg" alt="" style={{ width:'100%' }} />
          </div>
        </div>
      )
    } */
    
/* ******************************************************************************************************************************** */

/*     var img1,img2,img3,img4,img5,img6
    export const Team = (props) => {
    
        img1='img/team/our-team-1.png'
        img2='img/team/our-team-2.png'
        img3='img/team/our-team-3.png'

      
        return (
          <div id='team' className='text-center'>
            <div className='container'>
              <div className='section-title'>
              <h2>Our Team</h2>
              </div>
              <div className='row'>
                <div className='tokenomic-items'>
                  <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4'>
                    <div className='tokenomic-item'>
                      <div className='hover-bg'>
                        {' '}
                        <a
                          href={img1}
                          title='Project Title'
                          data-lightbox-gallery='gallery1'
                        >
                          <img
                            src={img1}
                            className='img-responsive'
                            alt='Project Title'
                          />{' '}
                        </a>{' '}
                      </div>
                    </div>
                  </div>
        
                <div className='tokenomic-items'>
                  <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4'>
                    <div className='tokenomic-item'>
                      <div className='hover-bg'>
                        {' '}
                        <a
                          href={img2}
                          title='Project Title'
                          data-lightbox-gallery='gallery1'
                        >
                          <img
                            src={img2}
                            className='img-responsive'
                            alt='Project Title'
                          />{' '}
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                 
                <div className='tokenomic-items'>
                  <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4'>
                    <div className='tokenomic-item'>
                      <div className='hover-bg'>
                        {' '}
                        <a
                          href={img3}
                          title='Project Title'
                          data-lightbox-gallery='gallery1'
                        >
                          <img
                            src={img3}
                            className='img-responsive'
                            alt='Project Title'
                          />{' '}
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                 

                    </div>
                  </div>
                </div>
                </div>
                    </div>
                  </div>         
                  
        )
      } */
      

/* ******************************************************************************************************************************** */



export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='section-title'>
        <h2>Our Team</h2>
        </div>
        <div className='row'>
          <div className='team-items'>


            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='team-item'>
              <img
                      src='img/team/our-team-2.png'
                      className='img-responsive'
                      alt='Project Title'
                      style={{width:'100vw'}}
                    />{' '}
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='team-item'>
              <img
                      src='img/team/our-team-1.png'
                      className='img-responsive'
                      alt='Project Title'
                      style={{width:'100vw'}}
                    />{' '}
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='team-item'>
              <img
                      src='img/team/our-team-3.png'
                      className='img-responsive'
                      alt='Project Title'
                      style={{width:'100vw'}}
                    />{' '}
              </div>
            </div>




                </div>
              </div>
            </div>
          </div>
    //     </div>
    //   </div>
    // </div>
  )
}
