var bg;
export const Faqs = (props) => {

   bg ='faqs_en';

    return (
      <div id='faqs' className='text-center'>
            <div className='container'>
            <div className='section-title'>
            <h2>{props.data ? props.data.head : 'Loading'}</h2>

          </div>
              <div className='row'>
                <div className={bg} >
                
            </div>
      </div>
      
      </div>
      </div>
    )
  }
  