import { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { Features } from './components/features'
import { About } from './components/about'
/* import { Roadmap } from './components/roadmap' */
/* import { Partners } from './components/partners' */
//import { Media } from './components/media'
/* import { Detail } from './components/detail'
import {Listing} from './components/listings' */
import { Team } from './components/team'
import { Contact } from './components/contact'
import JsonData_en from './data/data_en.json'

import SmoothScroll from 'smooth-scroll'
import { Faqs } from './components/faqs'
//import { Concept } from './components/concept'
/* import { Service} from './components/service' */
/* import {Tokenomic} from './components/tokenomic' */
import {Whitepaper} from './components/whitepaper'
import { PDFReader } from './components/PDFReader'


import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import { Popup } from './components/popup'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  var data_lang='';
  

    data_lang = JsonData_en

  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    
    setLandingPageData(data_lang)  
  }, [])

  


  
  return (
    <div>
    <BrowserRouter>
    <div>
      <Switch>
          <Route exact path = "/" >
      <Navigation data={landingPageData.Navigation} data2={landingPageData.Navigation2} data3={landingPageData.Navigation3}/>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features}  data2={landingPageData.Features2}/>
      <About data={landingPageData.About} />
      {/* <Concept data={landingPageData.Concept} /> */}
{/*       <Tokenomic data={landingPageData.Tokenomic}/> */}
{/*       <Service data={landingPageData.Service}/> */}
{/*       <Roadmap data={landingPageData.Roadmap} /> */}
      <Faqs data={landingPageData.Faqs}/>
      <Team/>
      {/* <Partners data={landingPageData.Partners}/> */}
{/*       <Detail data={landingPageData.Detail} data2={landingPageData.Detail2}/> */}
      {/* <Media data={landingPageData.Media}  data2={landingPageData.Media2}/> */}
{/*       <Listing data={landingPageData.Listing} data2={landingPageData.Listing2}/> */}
      {/* <Team data={landingPageData.Team} /> */}
      <Contact data={landingPageData.Contact} />
      {/* <Otp data={landingPageData.Otp}/> */}
      {/*<Popup/>*/}
      </Route>     
            {/* <Route exact path = "/whitepaper" component={PDFReader}/> */}
            <Route exact path = "/Whitepaper" component={Whitepaper}/>
            </Switch>
            </div>
  </BrowserRouter>
    </div>

  )
}

export default App;
