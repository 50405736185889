import React from 'react';


const ControlPanel = (props) => {
  const { file, pageNumber, numPages, setPageNumber, scale, setScale } = props;

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
  const lastPageClass = isLastPage ? 'disabled' : 'clickable';

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const onPageChange = (e) => {
    const { value } = e.target;
    setPageNumber(Number(value));
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
  const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <div className="control-panel " style={{margin:'1rem',padding:'1rem', display:'flex',justifyContent:'space-between',alignItems:'baseline'}}>
      <div  style={{display:'flex', justifyContent:'space-between', alignItems:'baseline'}}>
        <i
          className={`fas fa-fast-backward  ${firstPageClass}`} style={{margin:'0 1rem'}}
          onClick={goToFirstPage}
        />
        <i
          className={`fas fa-backward  ${firstPageClass}` } style={{margin:'0 1rem'}}
          onClick={goToPreviousPage}
        />
        <span>
          Page{' '}
          <input
            name="pageNumber"
            type="number"
            min={1}
            max={numPages || 1}
            className="p-0 pl-1 " style={{margin:'0 0.5rem',padding:'0'}}
            value={pageNumber}
            onChange={onPageChange}
          />{' '}
          of {numPages}
        </span>
        <i
          className={`fas fa-forward  ${lastPageClass}`} style={{margin:'0 1rem'}}
          onClick={goToNextPage}
        />
        <i
          className={`fas fa-fast-forward  ${lastPageClass}`} style={{margin:'0 1rem'}}
          onClick={goToLastPage}
        />
      </div>
      <div  style={{display:'flex', justifyContent:'space-between', alignItems:'baseline'}}>
        <i
          className={`fas fa-search-minus  ${zoomOutClass}`} style={{margin:'0 1rem'}}
          onClick={zoomOut}
        />
        <span>{(scale * 100).toFixed()}%</span>
        <i
          className={`fas fa-search-plus  ${zoomInClass}`} style={{margin:'0 1rem'}}
          onClick={zoomIn}
        />
      </div>
      {/* <div className="">
        <a href="/assets/docs/file-sample.pdf" download={true} title="download">
          <i className="fas fa-file-download clickable" />
        </a>
      </div> */}
      
    </div>
  );
};

export default ControlPanel;
